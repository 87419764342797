module controllers {
    export module tariff {

        interface IHSCodeKeyWordScope extends ng.IScope {
        }

        export class hsCodeKeyWordtrl {

            gridApi: uiGrid.IGridApi;
         
            allDirtyRows: Array<interfaces.tariff.IHsCodeKeyWord>;
            refresh: boolean = true;

            IsLoading: boolean = false;
            currentPage: number = 1;
            pageSize: number = 10;


            constructor(private $scope: IHSCodeKeyWordScope, private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private hsCodeKeyWordService: interfaces.tariff.IHsCodeKeyWordService,
            ) {
                this.loadHSCodeKeywords();
            }

            loadHSCodeKeywords(){

                    return this.bsLoadingOverlayService.wrap({
                        referenceId: 'tariff.hskeywords'
                    },
                        () => {
                            return this.hsCodeKeyWordService.getHsKeyWordList().query({
                                hsCode:"",
                                DescriptionEnglishUK:"",
                                DescriptionFrench:"",
                                DescriptionPortuguese:"",
                                DescriptionSpanish:"",
                                isActive:true,
                                numberRecords:15,
                                pageNumber:1
                            }, (result: Array<interfaces.costing.ICostOriginCountry>) => {
                                this.gvwHSCodeKeyWords.data = result;

                                if (result[0]) {
                                    this.gvwHSCodeKeyWords.totalItems = this.gvwHSCodeKeyWords.data.length;
                                } else {
                                    this.gvwHSCodeKeyWords.totalItems = 0;
                                }
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                    }

                    public registerGridApi(gridApi: uiGrid.IGridApi) {
                        this.gridApi = gridApi;
        
                        this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });
                    }

                    ListPageChange(newPage, pageSize) {
                
                        var dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });
        
                        if (this.allDirtyRows)
                        {
                            _.forEach(dirtyRows, (value) => {
        
                                //If a dirty row exists in the new dirty rows then overwrite the old one.
                                var row: interfaces.tariff.IHsCodeKeyWord = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));
        
                                if (row)
                                {
                                    //Row exists with the same Id.
                                    var i: number = _.indexOf(this.allDirtyRows, row);
                                    this.allDirtyRows[i] = row;
                                }
                                else
                                {
                                    this.allDirtyRows = _.concat(this.allDirtyRows, value);
                                }
        
                            });
                        }
                        else
                        {
                            this.allDirtyRows = dirtyRows;
                        }
                                        
                        this.currentPage = newPage;
                        this.pageSize = pageSize;
        
                        //this.DoSearch();
                    }
        

                    public gvwHSCodeKeyWords: uiGrid.IGridOptions = {
                        data: [],
                        enableSorting: true,
                        enableFiltering: true,
                        useExternalFiltering: false,
                        enableCellEdit: false,
                        useExternalSorting: false,
                        multiSelect: false,
                        enableColumnResizing: true,
                        enableRowSelection: false,
                        enableFullRowSelection: false,
                        enableRowHeaderSelection: false,
                        enableHorizontalScrollbar: 2,
                        enablePinning: false,
                        rowEditWaitInterval: -1,
                        paginationPageSizes: [10, 20, 50, 100],
                        paginationPageSize: 10,
                        enableVerticalScrollbar: 1,
                        useExternalPagination: false,
                        onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                        columnDefs: [
                            {
                                name: "EDIT",
                                displayName: "",
                                field: "",
                                cellTemplate: `
                                <div class="GridButton"> 
                                    <button type="button" ng-click="grid.appScope.hsCodeKeyWordtrl.removeInlineHSCodeKeyWord(row.entity)" class="btn btn-warning btn-sm">
                                        Delete
                                    </button>
                                </div>`,
                                width: 55,
                                enableCellEdit: false,
                                enableCellEditOnFocus: false,
                                enableColumnMenu: false,
                                headerCellTemplate: `
                                <div class="GridButton center-block"> 
                                    <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.hsCodeKeyWordtrl.addInline()">
                                        <span class="fa fa-plus"></span>
                                    </button>
                                </div>`
                            },
                            {
                            name: "HSCODE",
                            displayName: "* HS Code",
                            field: "HSCode",
                            enableCellEditOnFocus: true,
                            enableCellEdit: true,
                            width: 120,
                            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                            editableCellTemplate: `
                                        <form name="inputForm">
                                            <p class="input-group-sm">
                                                <input type="text" pattern="[0-9]*" ignore-wheel name="HSDCODE-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor  />
                                            </p>
                                        </form>`,
                            filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                        },{
                            name: "DescriptionEnglishUK",
                            displayName: "Description English UK",
                            field: "DescriptionEnglishUK",
                            enableCellEditOnFocus: true,
                            enableCellEdit: true,
                            width: 240,
                            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                            editableCellTemplate: `
                                        <form name="inputForm">
                                            <p class="input-group-sm">
                                                <input type="text"  maxlength="300" ignore-wheel name="HSDCODE-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor  />
                                            </p>
                                        </form>`,
                            filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                        },
                        {
                            name: "DescriptionFrench",
                            displayName: "Description French",
                            field: "DescriptionFrench",
                            enableCellEditOnFocus: true,
                            enableCellEdit: true,
                            width: 240,
                            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                            editableCellTemplate: `
                                        <form name="inputForm">
                                            <p class="input-group-sm">
                                                <input type="text"  maxlength="300" ignore-wheel name="HSDCODE-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor  />
                                            </p>
                                        </form>`,
                            filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                        },
                        {
                            name: "DescriptionPortuguese",
                            displayName: "Description Portuguese",
                            field: "DescriptionPortuguese",
                            enableCellEditOnFocus: true,
                            enableCellEdit: true,
                            width: 240,
                            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                            editableCellTemplate: `
                                        <form name="inputForm">
                                            <p class="input-group-sm">
                                                <input type="text"  maxlength="300" ignore-wheel name="HSDCODE-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor  />
                                            </p>
                                        </form>`,
                            filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                        },
                        {
                            name: "DescriptionSpanish",
                            displayName: "Description Spanish",
                            field: "DescriptionSpanish",
                            enableCellEditOnFocus: true,
                            enableCellEdit: true,
                            width: 240,
                            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                            editableCellTemplate: `
                                        <form name="inputForm">
                                            <p class="input-group-sm">
                                                <input type="text"  maxlength="300" ignore-wheel name="HSDCODE-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor  />
                                            </p>
                                        </form>`,
                            filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                        },
                         {
                            name: "ACTIVE",
                            displayName: "Is Active",
                            field: "IsActive",
                            width: 75,
                            enableCellEdit: true,
                            enableCellEditOnFocus: true,
                            cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.hsCodeKeyWordtrl.checkBoxChanged(row.entity)">',
                            editableCellTemplate: `
                                            <form name="inputForm">
                                                <p class="input-group-sm">
                                                    <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" ui-grid-editor>
                                                </p>
                                            </form>`,
                            filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                </div>`,
                            filter: {
                                condition: (searchTerm, cellValue) => {
                                    return searchTerm == cellValue;
                                }
                            },
                        }, {
                            name: "INFO",
                            displayName: "",
                            field: "",
                            cellTemplate: `
                                    <div class="GridButton"> 
                                        <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                            updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                                        </gts-stamp-info>
                                    </div>`,
                                width: 38,
                            enableSorting: false,
                            enableCellEdit: false,
                            enableCellEditOnFocus: false,
                            enableColumnMenu: false,
                            enableFiltering: false,
                        }, {
                            name: "NUMRECS",
                            displayName: "",
                            field: "NumRecs",
                            visible: false
                        }]
                    }

                    checkBoxChanged(entity) {
                        if (this.gridApi) {
                            this.gridApi.rowEdit.setRowsDirty([entity]);
                        }
                    }

                    removeInlineHSCodeKeyWord(entity: interfaces.tariff.IHsCodeKeyWord) {
                        if (entity.Id > 0)
                        {
                            return this.bsLoadingOverlayService.wrap({
                                referenceId: 'tariff.hskeywords'
                            }, () => {
                                return this.hsCodeKeyWordService.removeHSCodeKeyWord().delete({
                                    hsCodeKeyWordId: entity.Id,
                                }, (result) => {
                                    this.$timeout(() => {
                                        this.generalService.displayMessageHandler(result);
                                        this.DoSearch();
                                    });
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });
                        }
                        else
                        {
                            _.remove(this.gvwHSCodeKeyWords.data, (o) => {
                                return o === entity;
                            });
                        }
                    }

                    addInline() {
                        (<Array<any>>(this.gvwHSCodeKeyWords.data)).splice(0, 0, {
                            IsActive: true
                        });
                    }

                    DoSearch() {
                        return this.hsCodeKeyWordService.getHsKeyWordList().query({
                            hsCode:"",
                            DescriptionEnglishUK:"",
                            DescriptionFrench:"",
                            DescriptionPortuguese:"",
                            DescriptionSpanish:"",
                            isActive:true,
                            numberRecords:15,
                            pageNumber:1
                        }, (result: Array<interfaces.tariff.IHsCodeKeyWord>) => {
        
                            var currentDirtyRows: Array<interfaces.tariff.IHsCodeKeyWord> = [];
        
                            _.forEach(this.allDirtyRows, function (value) {
                                var row: interfaces.tariff.IHsCodeKeyWord = _.find(result, _.matchesProperty('Id', value.Id));
        
                                if (row)
                                {
                                    var i: number = _.indexOf(result, row);
                                    result[i] = value;
                                    currentDirtyRows.push(result[i]);
                                }
                            });
        
                            this.gvwHSCodeKeyWords.data = result;
        
                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }
                                        
                            if (result[0]) {
                                this.gvwHSCodeKeyWords.totalItems = this.gvwHSCodeKeyWords.data.length;
                            } else {
                                this.gvwHSCodeKeyWords.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    }

                    submit_click() {
                        var dirtyRows: Array<interfaces.tariff.IHsCodeKeyWord>;
                        dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });
        
                        if (this.allDirtyRows) {
                            _.forEach(dirtyRows, (value) => {
        
                                //If a dirty row exists in the new dirty rows then overwrite the old one.
                                var row: interfaces.tariff.IHsCodeKeyWord = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));
        
                                if (row) {
                                    //Row exists with the same Id.
                                    var i: number = _.indexOf(this.allDirtyRows, row);
                                    this.allDirtyRows[i] = row;
                                }
                                else {
                                    this.allDirtyRows = _.concat(this.allDirtyRows, value);
                                }
        
                            });
                        }
                        else {
                            this.allDirtyRows = dirtyRows;
                        }
        
                        if (this.allDirtyRows.length > 0) {
        
                            return this.bsLoadingOverlayService.wrap({
                                referenceId: 'tariff.hskeywords'
                            },
                                () => {
                                    //Get all the dirty rows and save them.
                                    return this.hsCodeKeyWordService.saveAll().save(this.allDirtyRows, (result: interfaces.applicationcore.IMessageHandler) => {
                                        this.generalService.displayMessageHandler(result);
        
                                        if (!result.HasErrorMessage)
                                        {
                                            this.allDirtyRows = [];
        
                                            this.refresh = false;
        
                                            this.$timeout(() => {
                                                this.refresh = true;
                                                this.DoSearch();
                                            });
                                        }
                                        
                                        this.allDirtyRows = [];
                                    }, (errorResponse) => {
                                        this.allDirtyRows = [];
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    }).$promise;
                                });
                        }
                    }
        
                    

        }

        angular.module("app").controller("hsCodeKeyWordtrl", controllers.tariff.hsCodeKeyWordtrl);
    }
}